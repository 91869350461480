import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Navbar from "./components/Navbar";
import ProjectDetailPage from "./components/pages/project-details/ProjectDetailPage";
import * as Data from "./data";
import * as EnglishData from "./data_en";


export default function App() {

  const siteData = (navigator.language || navigator.userLanguage).includes('nl') ? Data : EnglishData;

  return (
    <Data.DataContext.Provider value={siteData}>
      <div className="text-gray-400 bg-black body-font">
        <Navbar />
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Home displaySiteInEnglish />} />
            <Route exact path="/project/:id" element={
              <ProjectDetailPage />
            } />
            <Route index element={<Home />} />
            <Route
              path="*"
              element={<Home />}
            />
          </Routes>
        </BrowserRouter>
      </div>
    </Data.DataContext.Provider>
  );
}
