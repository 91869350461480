import React, { useContext } from "react";
import SectionHeader from "../../partials/SectionHeader";
import Socials from "../../partials/Buttons/Socials";
import { MailIcon } from "@heroicons/react/solid";
import { DataContext } from "../../../data";

export default function Contact() {
  const { home } = useContext(DataContext);

  return (
    <section id="contact" className="relative text-center pb-20">
      <SectionHeader title={home.contact.title} icon={<MailIcon/>} className={'mb-5'} />
      <Socials />
    </section>
  );
}
