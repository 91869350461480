import React from 'react';

const SectionHeader = ({ title, description, icon, className }) => {

    const surroundingClassName = `flex flex-col w-full mb-10 text-center ${className}`

    return <div className={surroundingClassName}>
        <div className='mx-auto inline-block w-10 mb-4'>
            {icon}
        </div>
        <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-white">
            {title}
        </h1>
        <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            {description}
        </p>
    </div>
}

export default SectionHeader;