import React, {useContext} from "react";
import Button from '../../partials/Buttons/Button'
import SecondaryButton from '../../partials/Buttons/SecondaryButton'
import Title from "../../partials/text/Title";
import { DataContext } from "../../../data";

export default function About() {

  const { home, CTA } = useContext(DataContext);

// eslint-disable-next-line max-len
  return (
    <section id="about">
      <div className="container mx-auto flex px-10 py-5 md:flex-row flex-col items-center lg:px-40">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <Title>
            Full Stack Software Engineer
          </Title>
          <p className="mb-8 leading-relaxed" dangerouslySetInnerHTML={{ __html: home.about.intro }}/>
          <p className="mb-8 leading-relaxed hidden md:block">
            {home.about.career}
          </p>
          <div className="flex justify-center">
            <Button {...{
              href: "#contact",
              label: CTA.contact
            }} />
            <SecondaryButton
              href="#projects"
              className="ml-4 inline-flex text-gray-400 bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-lg"
              label={CTA.projects} />
          </div>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 flex justify-center">
          <img
            className="rounded-full border border-gray-100 shadow-sm"
            alt="hero"
            src="./seppe.jpg"
          />
        </div>
      </div>
    </section>
  );
}
