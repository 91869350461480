
import React from 'react';

import About from "./components/pages/home/About";
import Contact from "./components/pages/home/Contact";
import Projects from "./components/pages/home/Projects";
import Skills from "./components/pages/home/Skills";
import Education from "./components/pages/home/Education";
import PersonalProjects from './components/pages/home/PersonalProjects';


export default function Home() {

    return <main>
        <About />
        <Projects />
        <PersonalProjects />
        <Skills />
        <Education />
        <Contact />
    </main>
}