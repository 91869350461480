import React from 'react';
import SubTitle from '../../partials/text/SubTitle';

const ProjectLinks = ({ links }) => {

    const www = links.www && <a href={links.www}>Ga naar website</a>,
        github = links.github && <a href={links.github}>Ga naar code repo</a>

    return <div>
        <SubTitle>Links</SubTitle>
        <div>{www}</div>
        <div>{github}</div>
        </div>
};

export default ProjectLinks;