import React from 'react';
import SubTitle from './text/SubTitle';

const FeatureList = ({ title, features }) => {
    return <div>
        <SubTitle>{title}</SubTitle>
        <ul className='list-disc m-1 mb-8'>
            {features.map(feature =>
                <li key={feature}>{feature}</li>)}
        </ul>
    </div>
};

export default FeatureList;