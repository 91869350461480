export const projects = [
  {
    id: 0,
    title: "Toyota Customer Portal",
    subtitle: "React, Redux, Node.JS, AWS S3",
    techStack: "React, Redux, Node.JS, AWS S3, AWS Cloudfront, MongoDB, HTML CSS, Git, Jira, Bamboo, Stash, Confluence",
    features: ["SSO for login, registration and reset password", "Follow up of bought vehicles in Vehicle Order Tracker", "Consult your maintenance history", "Check vehicle specifics", "Profile section, to consult and edit personal info", "Over The Air map updates", "Connected Services activation", "..."],
    description:
      "A Customer Portal for the European Toyota websites. In this full-stack solution, used by millions of Toyota customers across Europe, users are able to follow their Toyota vehicle orders, activate their connected services and enjoy many vehicle-specific features.",
    images: ["/toyota-customer-portal-1.jpg", "/toyota-customer-portal-2.jpg", "/toyota-customer-portal-3.jpg", "/toyota-customer-portal-4.jpg", "/toyota-customer-portal-5.jpg", "/toyota-customer-portal-6.jpg", "/toyota-customer-portal-7.jpg"],
    links: { www: "https://nl.toyota.be/apps/customerportal#/" }
  },
  {
    id: 1,
    title: "Notification Centre",
    subtitle: "Node.JS, AWS Lambda, MongoDB",
    techStack: "AWS Lambda, Mongo DB, Node.JS, Express.JS, React.JS, Redux, HTML, CSS",
    features: ["Serverless AWS Lambda backend", "React Redux B2C UI to consult and delete notifications", "B2C APIs to request notifications for customers", "B2B API to create notifications", "Admin UI to send notifications in bulk", "Localisation of notifications on a CMS"],
    description:
      "Serverless notification ecosystem that features high-performance creation and consulting of notifications, both inbox and push notifications. This project entailed both a B2C UI and a B2B Admin tool, the latter was used to schedule notifications in bulk. ",
    images: ["/notification-center-1.jpg", "/notification-center-2.jpg", "/notification-center-3.jpg"],
    links: { www: "https://nl.lexus.be/#/sso/login" },
  },
  {
    id: 2,
    title: "Subaru SSO",
    subtitle: "React, Forgerock, AWS SNS, AWS Lambda",
    techStack: "React.JS, Redux, Node.JS, MongoDB, Jest",
    description:
      "Full stack solution that took care of all SSO functionality for the Subaru Care app.",
    images: ["/subaru3.PNG", "/subaru2.PNG", "/subaru1.PNG"],
    links: { www: "https://play.google.com/store/apps/details?id=eu.subaru.subarucare&pli=1" },
  },
  {
    id: 3,
    title: "Electrific",
    subtitle: "Java, Dropwizard, Postgresql, Android (Java)",
    techStack: "Java, Dropwizard, Android (Java), Postgresql, Liquibase",
    description:
      "EU Horizon project which tried to create an all-around navigation solution for EV owners, resulting in a multifunctional Android App.",
    images: ["/electrific.png", "/electrific2.png", "/electrific3.png"],
    links: { www: "https://electrific.eu" },
    disableStrechedImage: false
  },
  {
    id: 4,
    title: "Native Language identification",
    subtitle: "Natural Language Processing - Machine Learning",
    techStack: "Java, Liblinear, LaTeX",
    features: ["Loanwords", "Lexemes", "Lemmas", "POS", "Characters", "Complex features"],
    description:
      "In this master thesis, I developed a Machine Learning algorithm that tried to predict the native language of the author of an English text. The algorithm was based on Support Vector Machines and used Liblinear as an utility.",
    images: ["/thesis.PNG"],
    links: { www: "https://www.dropbox.com/s/mpl0eur34xpy8ey/finalThesisPresentation.pptx%20%284%29.pptx?dl=0" },
  },
  {
    id: 5,
    title: "Lexus Customer Portal",
    subtitle: "React, Redux, Node, AWS S3",
    techStack: "React, Redux, Node.JS, AWS S3, AWS Cloudfront, MongoDB, HTML CSS, Git, Jira, Bamboo, Stash, Confluence",
    description:
      "Customer Portal for the European Lexus websites. In this full-stack solution, users are able to follow their Lexus vehicle orders, activate their connected services and enjoy many vehicle-specific features",
    features: ["SSO for login, registration and reset password", "Follow-up of orders in Vehicle Order Tracker", "Consult your maintenance history", "Check vehicle specifics", "Profile section, to consult and edit personal info", "Over The Air map updates", "Connected Services activation", "..."],
    images: ["/lexus-customer-portal.PNG", "/lexus-customer-portal-2.jpg"],
    links: { www: "https://nl.lexus.be/#/sso/login" },
  },
];

export const educations = [
  {
    course:
      "Master in IT: Artificial Intelligence",
    image: "https://www.imptox.eu/media/5483/ku.png",
    grade: "Cum Laude",
    institution: "KU Leuven",
  },
  {
    course:
      "Schakelprogramma Applied IT",
    image: "https://www.imptox.eu/media/5483/ku.png",
    grade: "Cum Laude",
    institution: "KU Leuven",
  },
  {
    course:
      "Erasmus IP Program: Forensics and security",
    image: "./bournemouth.jpg",
    grade: "",
    institution: "Bournemouth University",
  },
  {
    course:
      "Applied IT: Application Development",
    image: "https://www.pxl.be/Assets/website/pxl_algemeen/afbeeldingen/grotere_versie/1314_logo_pxl_bol.png",
    grade: "Magna Cum Laude",
    institution: "PXL Hogeschool Limburg",
  },
];

export const skills = [
  "JavaScript",
  "React",
  "Node.js",
  "Java",
  "NLP",
  "Machine Learning",
  "Typescript",
  "Redux",
  "AWS S3, SNS, SQS, Lambda, Cloudformation",
  "Next.js",
  "Recoil.js",
  "HTML",
  "(S)CSS",
  "Tailwind",
  "MongoDB",
  "SQL"
];

export const home = {
  about: {
    intro: "Welcome to my portfolio. This site will be used as a display for several of my projects, both professional and personal ones. For more info on my career, please visit my <a class=\"underline\" href=\"https://www.linkedin.com/in/seppedijkmans/?originalSubdomain=be\">LinkedIn page</a>.",
    career: "Currently I am working as Senior Software Engineer at Inetum-Realdolmen, working on Full Stack solutions."
  },
  projects: {
    title: 'Professional Projects',
    description: 'Click on the tile for more info.'
  },
  personalProjects: {
    title: 'Hobby Projects',
    description: 'Spare time projects. Click on the tile for more info.'
  },
  skills: {
    title: 'Skills & Technologies',
    description: 'An overview of my skillset.'
  },
  education: {
    title: "Education",
    description: 'For more info, check my LinkedIn profile.'
  },
  contact: {
    title: 'Contact'
  }
}

export const CTA = {
  projects: 'Projects',
  contact: 'Contact',
  education: 'Education',
  skills: 'Skills'
}

export const personalProjects = [
  {
    id: 14,
    title: "Simple Chat App",
    subtitle: "React Native, Socket.Io, Nest.JS",
    techStack: "React Native, Socket.Io, Nest.JS, Typescript, Android, IOS, React Navigation, React Event Emitter",
    features: ["Group Chat", "Websockets", "Input screen to enter username"],
    description:
        "React Native toy project that gives users the option to join a group chat and exchange messages. This is a full stack project, as the backend is powered by a custom Nest.JS Websocket service.",
    images: ["/chat-app-1.jpg", "/chat-app-2.jpg"],
    links: {
      github: "https://github.com/revolution64/chatapp/tree/master"
    }
  },
  {
    id: 13,
    title: "AI Text Summarizer",
    subtitle: "OpenAI API, ChatGPT, Node.js",
    techStack: "OpenAI API, ChatGPT-3.5, Express.js, Node.js, Render",
    features: ["AI Text Summarization", "Features different languages", "Summary Length Parameters"],
    description:
        "An AI toy project that summarizes a source text into a well-structured summary. Includes an option to choose summary length and language. The frontend was partially generated by Chat-GTP.",
    images: ["/AI-Text-Summarizer.PNG"],
    links: {
      www: "https://ai-text-summarizer.onrender.com/",
      github: "https://github.com/revolution64/textSummarizer/tree/master"
    }
  },
  {
    id: 12,
    title: "Synonym Searcher",
    subtitle: "Python, Vue.js, Flask, spacy",
    techStack: "Python, Flask, PythonAnywhere, Vue.js, OpenDutchWordNet",
    features: ["Natural Language Processing", "Machine Learning", "POS Tagging", "Lemmatization", "Synoniemen zoeken voor veelgebruikte woorden"],
    description:
      "Webapp that tries to find synonyms by using NLP, Lemmatization and POS tagging. Prototype is available on request.",
    images: ["/synoniemenzoeker-0.PNG", "/synoniemenzoeker-1.PNG"],
    links: {
      www: "https://revolution64.pythonanywhere.com/static/index.html",
      github: "https://github.com/revolution64/cmt-server"
    }
  },
  {
    id: 6,
    title: "Pi Weather Station",
    subtitle: "Next.JS, React.JS",
    techStack: "Raspberry Pi, Linux. Next.JS, React.JS, Node.JS",
    features: ["Always-on display", "Live forecasts", "Humidity", "Air pressure", "Tme and date display", "Joke of the day", "Quote of the day", "Calendar event overview"],
    description:
      "An always-on Raspberry Pi weather station, showing weather forecasts, joke of the day, calendar events and other functionalities.",
    images: ["/pi-weather-station-2.PNG", "/pi-weather-station-1.jpg", "/pi-weather-station-3.PNG", "/pi-weather-station-4.PNG", "/pi-weather-station-5.PNG"],
    links: { github: "https://bitbucket.org/revolution64/pi-display" }
  },
  {
    id: 7,
    title: "Pi Powered Game Boy Advance",
    subtitle: "Raspberry Pi",
    techStack: "Raspberry Pi, Linux",
    description:
      "A custom made Game Boy Advance, powered by Raspberry Pi Zero and a FreePlay circuit board.",
    images: ["/pi-gba-1.PNG", "/pi-gba-2.jpg"],
  },
  {
    id: 8,
    title: "Pi Robot",
    subtitle: "Websocket, Express.JS, React.JS, Linux",
    techStack: "Websocket, Express.JS, React, Node.JS, Linux, Raspberry Pi",
    features: ["Remote controlled robot car", "React UI with livestream", "Keyboard control", "Responsive Interface"],
    description:
      "Raspberry Pi Robot with camera, includes remote control and livestream through a React webapp.",
    images: ["/pi-robot-car.jpg"],
    links: {  github: "https://bitbucket.org/revoblog/pi-robot-express" },
  },
  {
    id: 9,
    title: "Pi Night Camera",
    subtitle: "Linux, Node, Pi Camera",
    techStack: "Node, Linux, Pi Camera",
    description:
      "Raspberry Pi camera with night vision and motion detection.",
    images: ["/pi-night-camera.jpg"],
  },
  {
    id: 10,
    title: "Pi Print Server",
    subtitle: "Linux, CUPS",
    techStack: "Linux, CUPS",
    description:
      "Recyled an old printer, by making it a network printer, powered by CUPS and a Raspberry Pi Zero.",
    images: ["/pi-print-server.PNG"]
  },
  {
    id: 11,
    title: "Mr White",
    subtitle: "React",
    techStack: "React, SCSS",
    description:
      "A React version of Undercover: the Forgetful Spy",
    images: ["/mrwhite-1.PNG", "/mrwhite-2.PNG", "/mrwhite-3.PNG", "/mrwhite-4.PNG"],
    links: { github: "https://github.com/revolution64/mrWhite"}
  }
];

export const allProjects = projects.concat(personalProjects);
