import { ChipIcon } from "@heroicons/react/solid";
import React, { useContext } from "react";
import { skills } from "../../../data";
import SectionHeader from "../../partials/SectionHeader";
import SkillTile from "../../partials/SkillTile";
import { DataContext } from "../../../data";

export default function Skills() {

  const { home } = useContext(DataContext);

  return (
    <section id="skills">
      <div className="container px-5 py-5 mx-auto">
        <SectionHeader title={home.skills.title} description={home.skills.description} icon={<ChipIcon />} />
        <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
          {skills.map((skill) => (
            <SkillTile key={`${skill}`} skill={skill} />
          ))}
        </div>
      </div>
    </section>
  );
}
