import React, { useContext } from "react";
import { BookOpenIcon } from "@heroicons/react/solid";
import SectionHeader from "../../partials/SectionHeader";
import EducationTile from "../../partials/EducationTile";
import { DataContext } from "../../../data";

export default function Education() {
  const { home, educations } = useContext(DataContext);

  return (
    <section id="testimonials">
      <div className="container px-5 py-5 mx-auto text-center lg:px-40">
        <SectionHeader title={home.education.title} description={home.education.description} icon={<BookOpenIcon />} />
        <div className="flex flex-wrap -m-4 ">
          {educations.map((education) => (
            <EducationTile key={`${education.course}`} {...{education}} />
          ))}
        </div>
      </div>
    </section>
  );
}