import React, { useContext } from "react";
import Socials from "./partials/Buttons/Socials";
import { DataContext } from "../data"

export default function Navbar() {

  const { CTA } = useContext(DataContext);

  return (
    <header className="bg-gray-800 md:sticky top-0 z-10">
      <div className="container mx-auto flex flex-wrap p-3 flex-col md:flex-row items-center">
        <a className="title-font font-medium text-white mb-1 md:mb-0">
          <a href="/home#about" className="md:ml-3 text-xl">Seppe Dijkmans</a>
        </a>
        <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-700	flex flex-wrap
         items-center text-base justify-center">
          <a href="/home#projects" className="m-2 hover:text-white">
            {CTA.projects}
          </a>
          <a href="/home#skills" className="m-2 hover:text-white">
            {CTA.skills}
          </a>
          <a href="/home#testimonials" className="m-2 hover:text-white">
            {CTA.education}
          </a>
        </nav>
        <Socials className={"hidden md:flex"}/>
      </div>
    </header>
  );
}
