import React from 'react';
import { BadgeCheckIcon } from '@heroicons/react/solid';

function SkillTile({skill}) {
    return (<div key={skill} className="p-2 sm:w-1/2 w-full">
            <div className="bg-gray-800 rounded flex p-4 h-full items-center">
              <BadgeCheckIcon className="text-yellow-200 w-6 h-6 flex-shrink-0 mr-4" />
              <span className="title-font font-medium text-white">
                {skill}
              </span>
            </div>
          </div>);
  }

 export default SkillTile; 