import React from 'react';
import FeatureList from '../../partials/FeatureList';
import BodyText from '../../partials/text/BodyText';
import Title from '../../partials/text/Title';
import SubTitle from '../../partials/text/SubTitle';
import ImageGallery from 'react-image-gallery';
import ProjectLinks from './ProjectLinks';

const ProjectDetails = ({ title, description, techStack, features, images, links }) => {
    const formattedImages = Array.isArray(images) && images.map((image) => ({ original: '//' + window.location.host + image }));

    return <div>
        <Title className={'ml-0'}>{title}</Title>
        <BodyText>{description}</BodyText>
        <SubTitle>Tech Stack</SubTitle>
        <BodyText>{techStack}</BodyText>
        {features && <FeatureList {...{ title: 'Features', features }} />}
        {images && <div ><SubTitle>Afbeeldingen</SubTitle>
            <ImageGallery items={formattedImages} additionalClass={'mt-4 mb-8 max-w-md h-150px'} />
        </div>}
        {links && <ProjectLinks links={links} />}
    </div>;
};

export default ProjectDetails;